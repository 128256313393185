@import "../../styles/variables";

.loading-dots{
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $spacing-small 0;
    gap: $spacing-tiny;
    
    .dot{
        border-radius: 50%;
        transition: $transition;
        &.dot-1{
            animation: fillDots .8s ease-in-out .15s infinite;
        }
        &.dot-2{
            animation: fillDots .8s ease-in-out .25s infinite;
        }
        &.dot-3{
            animation: fillDots .8s ease-in-out .35s infinite;
        }
    }
}


@keyframes fillDots {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
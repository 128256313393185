@import '../../styles/variables';

.main-wrapper{
    width: 100%;
    height: 100%;

    background-color: $color-primary;

    .main-content{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: $spacing-big;
        animation: slide .3s linear;
        animation-fill-mode: forwards;

        .image-container{
            width: 50rem;

            &.bigger{
                width: 70rem;
            }
            &.smaller{
                width: 30rem;
            }
        }
        h1, p{
            color: $text-color-light;
        }
        .main-content-tile-flex{
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
    }
}

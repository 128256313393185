$color-primary: #083D77;
$color-secondary: #ED2E07;
$color-white: #fff;

$color-primary-light: #1A81EF;
$color-primary-very-light: #A0CBF8;
$color-secondary-light: #F95738;

$button-color-1: #623CEA;
$button-color-2: #B8336A;
$button-color-3: #0CAC64;

$button-color-1-light: #886BEF;
$button-color-2-light: #D35F8F;
$button-color-3-light: #10E585;

$button-color-1-dark: #3814B8;
$button-color-2-dark: #80234A;
$button-color-3-dark: #087243;

$text-color: #0A1612;
$text-color-light: #e7e7e7;
$link-color: #C00C99;
$link-color-light: #F22CC7;

$font-size-big: 2.5rem;
$font-size-medium: clamp(2rem, 1.6vw, 2.3rem);
$font-size-small: clamp(1.5rem, 1.4vw, 1.8rem);
$font-size-small2: clamp(1.2rem, 1.4vw, 1.5rem);
$font-size-tiny: clamp(1.1rem, 1vw, 1.2rem);

$spacing-tiny: .4rem;
$spacing-small: 1rem;
$spacing-medium: 2rem;
$spacing-big: 3.5rem;

$icon-extra-big: 5rem;
$icon-big: 2.5rem;
$icon-medium-2: 2rem;
$icon-medium: 1.8rem;
$icon-small: 1.2rem;

$border-radius-big: 1rem;
$border-radius-small: .52rem;

$commom-margin: 18rem;
$box-shadow: -1px 2px 2.5px 0px rgba(0,0,0,0.25);
$box-shadow-hover: -2px 3px 4px 0px rgba(0,0,0,0.3);

$transition: ease-in-out .3s;


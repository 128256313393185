@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Poppins:wght@400;500&display=swap');
@import "./variables";

@import "./node_modules/bootstrap/scss/bootstrap";

:root{
  font-size: 62.4%;
  scroll-behavior: smooth;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root{
  width: 100%;
  height: 100vh;
}

img{
  width: 100%;
  object-fit: cover;
}
h1{
  font-size: clamp(4rem, 3.5vw, 6rem);
  font-weight: bold;
  letter-spacing: .2rem;
}
h2{
  font-size: clamp(3rem, 2.5vw, 3.5rem);
  letter-spacing: .2rem;
}
h3{
  font-size: $font-size-medium;
}
p{
  font-size: $font-size-small;
  letter-spacing: .1rem;
  line-height: 1.5;
}
a{
  text-decoration: none;
  font-size: $font-size-small;
  font-weight: 600;
  transition: $transition;
  color: $link-color;
}
a:hover{
  color: $link-color-light;
}

.icon{
  width: $icon-medium;
  height: $icon-medium;
}
input, select{
  // line-height: 2rem;
  font-size: $font-size-small2;
  outline: none;
  color: black;
  transition: $transition;
  padding: $spacing-small;
  font-weight: 400;
  border: 1px solid rgba(0,0,0,0.3);

}

button{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: $border-radius-small;
  gap: $spacing-small;
  font-size: $font-size-small;
  font-weight: 600;
  transition: $transition;

}
hr{
  width: 100%;
  height: 2px;
}

*:disabled{
  opacity: .5 !important;
  cursor: not-allowed !important;
}

.darkmode{
  background-color: $color-secondary;
  h1,h2,h3{
    color: $color-primary;
  }
}



@-webkit-keyframes bounceUp {
  0%, 100% {
      -webkit-transform: translateY(0);
  }
  50% {
      -webkit-transform: translateY(-5px);
  }
}
@keyframes bounceUp {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-5px);
  }
}
@-webkit-keyframes bounceDown {
  0%, 100% {
      -webkit-transform: translateY(0);
  }
  50% {
      -webkit-transform: translateY(5px);
  }
}
@keyframes bounceDown {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(5px);
  }
}

@media only screen and (max-width: 3840px) {
  .card-component{
      width: 30rem;
  }
}
@media only screen and (max-width: 2560px) {
  .card-component{
      width: 23rem;
  }
}
@media only screen and (max-width: 1920px) {
  .card-component{
      width: 15rem;
  }
}
@media only screen and (max-width: 1600px) {
  .card-component{
      width: 25rem;
  }
}
@media only screen and (max-width: 1440px) {
  .card-component{
      width: 25rem;
  }
}
@media only screen and (max-width: 1024px) {
  .card-component{
      width: 35rem;
  }
}
@media only screen and (max-width: 768px) {
  .card-component{
      width: 15rem;
  }
}